import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Video Image Banding",
  "path": "/Frequently_Asked_Question/Video_Banding_Due_To_LEDs/",
  "dateChanged": "2020-02-18",
  "author": "Mike Polinowski",
  "excerpt": "I see some kind of banding in my video image",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='FAQs - Video Image Banding' dateChanged='2020-02-18' author='Mike Polinowski' tag='INSTAR IP Camera' description='I see some kind of banding in my video image' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Video_Banding_Due_To_LEDs/' locationFR='/fr/Frequently_Asked_Question/Video_Banding_Due_To_LEDs/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <p><strong parentName="p">{`Q`}</strong>{`: I see some kind of banding in my video image. It moves as well and is affecting the colour. My office uses LED lighting (Normal ES 12w lamps). I mean the kind of horizontal banding you get if you have the wrong filter (50hz/60hz) or neither. This seems to occur on the web interface and the software and the web interface.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "853px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/16cb1c7b4326205f29e9df5fc4f441d2/66caf/Video_Banding_Due_To_LEDs_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABkElEQVQY04WOzU/TAABH+4dxNt5dIFGRtSDbHPvQtetgLS0fWxZmyIAhuiUboJlD0LVuJQIqGwnx5Ek9aDx7oTE6yMBk86nERG+8yy+/y8sTOp1T/vGTXq/73+1xId2zv/uD7lkHwXHqVB5XqNUsGs4OdmMX23mNtd3Ecvap1V9iNXZpbO/hOC+wLZuDVotnGxUebtpsfTrB/nzCk4/HvPlyiqAZU1zxDCBKPoZvRpBG40h+HenWDKLfYGgkgVeSEb0hBj1exGvXCUWCeC710X8jwFjrG7GmS3TfpfiujZAvlAiMRYkpCZSEgZpMMW7Ok0wvoWUWmMnlWSg+oLBeYLVaZL16n9KjJUrlLCtPNwk1v6K0XHx7R5TetxEW7y2jmSbJ8TiyIhOT/4h1ErrJxOQ0xmya2UyK6kaF5/UtsjmT9N1pMlmdVHmNubdt1AOXO02X1Q9thKlJDdEroYaDxMM+1GgI5XYYTZVJmRq5rEk+p7E8bzBnxlEjI/gDQ1ztv8zohI56+P28MPjqiPLvwl/L5l7KKQuhtAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/16cb1c7b4326205f29e9df5fc4f441d2/e4706/Video_Banding_Due_To_LEDs_01.avif 230w", "/en/static/16cb1c7b4326205f29e9df5fc4f441d2/d1af7/Video_Banding_Due_To_LEDs_01.avif 460w", "/en/static/16cb1c7b4326205f29e9df5fc4f441d2/469bf/Video_Banding_Due_To_LEDs_01.avif 853w"],
              "sizes": "(max-width: 853px) 100vw, 853px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/16cb1c7b4326205f29e9df5fc4f441d2/a0b58/Video_Banding_Due_To_LEDs_01.webp 230w", "/en/static/16cb1c7b4326205f29e9df5fc4f441d2/bc10c/Video_Banding_Due_To_LEDs_01.webp 460w", "/en/static/16cb1c7b4326205f29e9df5fc4f441d2/d8b1f/Video_Banding_Due_To_LEDs_01.webp 853w"],
              "sizes": "(max-width: 853px) 100vw, 853px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/16cb1c7b4326205f29e9df5fc4f441d2/81c8e/Video_Banding_Due_To_LEDs_01.png 230w", "/en/static/16cb1c7b4326205f29e9df5fc4f441d2/08a84/Video_Banding_Due_To_LEDs_01.png 460w", "/en/static/16cb1c7b4326205f29e9df5fc4f441d2/66caf/Video_Banding_Due_To_LEDs_01.png 853w"],
              "sizes": "(max-width: 853px) 100vw, 853px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/16cb1c7b4326205f29e9df5fc4f441d2/66caf/Video_Banding_Due_To_LEDs_01.png",
              "alt": "Image banding due to LED lights",
              "title": "Image banding due to LED lights",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`A`}</strong>{`: The Panasonic sensor used in our Full HD cameras automatically adjusts to the frequency of your light source - unlike older models where you still had to pick the correct value (50Hz/60Hz).`}</p>
    <p>{`The image distortion you are seeing above happens because of the image processing done by the `}<Link to="/Web_User_Interface/1080p_Series/Multimedia/Image/" mdxType="Link">{`Hardware WDR`}</Link>{`. Please open the `}<Link to="/Web_User_Interface/1080p_Series/Multimedia/Image/" mdxType="Link">{`Multimedia/Image Settings`}</Link>{` inside your camera's WebUI and select the Software WDR instead.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      